import React, { useEffect, useState } from 'react'
// import introVideo from '../Img/intoVid.mp4'
import '../CSS/TopIntroSec.css'
import googlePlay from '../Img/logos/googlePlay.png'
import graphy from '../Img/logos/graphy.png'
import hostinger from '../Img/logos/hostinger.png'
import interkart from '../Img/logos/interkart.png'
import paytm from '../Img/logos/paytm.png'
import EntrollNowBtn from './EntrollNowBtn'
import { NavLink } from 'react-router-dom'
import TrustPilotRating from "../Img/trustpilot-stars-logo.png";
const TopIntroSection = ({ checkoutHandler, amount }: any) => {

  const initialTime = 30 * 60; // 30 minutes in seconds
  const [time, setTime] = useState<number>(
    localStorage.getItem('countdownTimer') ? parseInt(localStorage.getItem('countdownTimer') || '0') : initialTime
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) {
          const updatedTime = prevTime - 1;
          localStorage.setItem('countdownTimer', updatedTime.toString());
          return updatedTime;
        } else {
          clearInterval(interval);
          localStorage.removeItem('countdownTimer');
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const minutes: number = Math.floor(time / 60);
  const seconds: number = time % 60;

  const formatTime = (value: number): string => {
    return String(value).padStart(2, '0');
  };


  return (
    <div className='Wrapper'>
      <NavLink to="/login" className="redirLogin">Login</NavLink>
      <div className="introTxt">
        <p className="subOne"><span className='white'>Learn </span><span className="black">WordPress Step-by-Step</span> </p>
        <p className="subTwo"><span className='white'>In 14 Days </span> <span className="red">NO-CODING</span> </p>
        <p className="subThree"><span className="white">In Just </span><span className='red strikethrough'>₹1499 </span><span className='black'>₹199/-</span></p>
        {/* <p className='verifyTrustPilot shine-effect'><span>4.8 Star Ratings </span> For Rajnish Kumar on Trustpilot <img src={TrustPilotRating} alt="Author Rating" />👉 <span><a href="https://www.trustpilot.com/review/wpskills.in" target="_blank" rel="noopener noreferrer">Verify Here</a></span></p> */}
      </div>

      <div className="VideoNdescr">
        <div className="leftSec">
          <div className="videoIntro">
            <div style={{ left: 0, width: '100%', height: 0, position: 'relative', paddingBottom: '56.25%' }}>
              <figure style={{ left: 0, width: '100%', height: 0, position: 'relative', paddingBottom: '56.25%', marginBlockEnd: 0, marginBlockStart: 0, marginInlineStart: 0, marginInlineEnd: 0 }}>
                <iframe
                  src="https://fast.wistia.net/embed/iframe/bn705y70yf"
                  scrolling="no"
                  style={{ border: 0, top: 0, left: 0, width: '100%', height: '100%', position: 'absolute', overflow: 'hidden', borderRadius: '0' }}
                  allowFullScreen={true}
                  allow="autoplay"
                  title='intro video'
                ></iframe>
              </figure>
            </div>


          </div>
          <div className="workedWith">
            <p className="workedWithPara">Worked With</p>
            <div className="workedLogos">
              <div className="logItem"><img src={paytm} alt="worked with" /></div>
              <div className="logItem"><img src={hostinger} alt="worked with" /></div>
              <div className="logItem"><img src={graphy} alt="worked with" /></div>
              <div className="logItem"><img src={interkart} alt="worked with" /></div>
              <div className="logItem"><img src={googlePlay} alt="worked with" /></div>
            </div>
          </div>
        </div>
        <div className="RytSec">
          <div className="innerCenter">
            <p className="title">Become a Web Developer Without Coding/Programming Knowledge</p>
            <p className="courseRating">Course Ratings
              <span className="startCount">
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
                <i className="fa-solid fa-star"></i>
              </span>
            </p>

            <div className="countDown">
              <div className="contDwnItem">
                <p className="num">00</p>
                <p className="txt">hrs</p>
              </div>
              <div className="contDwnItem">
                <p className="num">{formatTime(minutes)}</p>
                <p className="txt">mins</p>
              </div>
              <div className="contDwnItem">
                <p className="num">{formatTime(seconds)}</p>
                <p className="txt">secs</p>
              </div>
            </div>
            <p className="regEndSoon"><u>Registration</u> Ends Soon. Hurry Up!</p>
            <p className="join14Dys">Join this WordPress 14 Days Challenge Course in Just ₹199 Only</p>

            {/* <div className="enrollButton">
              <button className="enrollNow"><p>Enroll Now In ₹199/-</p><p>One time fee</p></button>
            </div> */}

            <EntrollNowBtn checkoutHandler={() => checkoutHandler(amount)} />

            <div className="tickMarkGSTsec">
              <i className="fa-solid fa-check"></i><span> Course in Hindi (हिंदी में)</span> &nbsp; &nbsp; &nbsp; &nbsp;<i className="fa-solid fa-check"></i> <span>18% GST Applicable</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopIntroSection