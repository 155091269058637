import React from 'react'
import "../CSS/Footer.css"
import {Link} from "react-router-dom"
const Footer = () => {
  return (
    <div className='FooterWrapper'>
        <div className="innerFlex">
          <div className="item"><Link to="/about">About us</Link></div>
          <div className="item"><Link to="/disclaimer">Disclaimer</Link> </div>
          <div className="item"><Link to="/privacy">Privacy Policy</Link></div>
          <div className="item"><Link to="/refund">Refund Policy</Link></div>
          <div className="item"><Link to="/terms">Terms</Link></div>
          <div className="item"><Link to="/contact">Contact</Link></div>
          <div className="item"><Link to="/shipping">Shipping</Link></div>
          {/* <div className="item"><a href="https://merchant.razorpay.com/policy/Mns6gD6zrQ7XgQ/shipping">Shipping</a></div> */}
        </div>
    </div>
  )
}

export default Footer