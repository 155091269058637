import React, { ReactNode, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { hamburgerMenuClose } from '../Redux/menuAction';
import {useDispatch} from 'react-redux'

interface Props {
    children?: ReactNode
}


const MayShowNavbar = ({ children }: Props) => {
    const dispatch = useDispatch()

    const location = useLocation();
    const [showNavbar, setShowNavbar] = useState(false);

    useEffect(() => {
        if(location.pathname==='/'){
            setShowNavbar(false);
        }else{
            setShowNavbar(true);
        }
        dispatch(hamburgerMenuClose(false));
    },[location,dispatch]);

    return (
        <div className='hdrTopFixed'>{showNavbar && children}</div>
    )
}

export default MayShowNavbar