import React, { useEffect, useState } from 'react'
import "../CSS/Header.css"
import { NavLink, useNavigate } from 'react-router-dom';
import { hamburgerMenuClose, hamburgerMenuOpen } from '../Redux/menuAction';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedUserData } from "../Redux/loggedUserAction";


const BtnStyleLink = {
  background: "transparent",
  border: "none",
  outline: "none",
  fontSize: "18px",
  fontWeight: '500' as any,
}




const Header = ({ openNotif }: any) => {

  const navigate = useNavigate();
  const isHamMenuOpn = useSelector((state: any) => state.menuStateReducer);

  const dispatch = useDispatch();


  const shoWNotification = () => {
    openNotif(true);
    let scrlPos = document.body.getBoundingClientRect().top;
    localStorage.setItem('currScrollPos', scrlPos.toString());
    window.scrollTo({ top: 0 });

    dispatch(hamburgerMenuClose(false));
    document.querySelector('.hdrTopFixed')?.classList.add("removeColor");
    document.querySelector('#portal')?.classList.add("fullHeight");
    document.querySelector('.App')?.classList.add("makeBlur");
    document.querySelector('body')?.classList.add("removeScroll");
  }

  const handleLogout = () => {
    let keysToRemove = ["token", "user"];
    for (const key of keysToRemove) {
      localStorage.removeItem(key);
    }
    navigate("/login");
    dispatch(getLoggedUserData());  
  }

  // const [showProfSubMenu, setshowProfSubMenu] = useState(false);
  // const handleProfileSubStngs=()=>{
    
  //   setshowProfSubMenu(!showProfSubMenu);
  // }
  let loggedUserFetch  = JSON.parse(localStorage.getItem("user") as string);
  return (
    <nav className='headerWrapper'>

      <div className="openHamIcon" onClick={() => dispatch(hamburgerMenuOpen(true) as any)}>
        <i className="fa-solid fa-bars"></i>
      </div>

      <div className={isHamMenuOpn ? "expandMenu keepOpen" : "expandMenu"}>
        <div className="closeIcon" onClick={() => dispatch(hamburgerMenuClose(false) as any)}>
          <i className="fas fa-times"></i>
        </div>

        <div className="leftSection menuItem">
            <img src="https://wpskills.in/wpskills-logo.png" alt="WP-Skills" className='logoImage'/>          
        </div>
        <div className="rytSection">
          {/* {
            localStorage.getItem("token") && localStorage.getItem("user")? <button className="menuItem" onClick={shoWNotification} style={BtnStyleLink}><i className="fa-brands fa-facebook-messenger"></i> NOTIFICATION <span></span></button>: null
          } */}
          <NavLink to="/about" className="menuItem"> About Us</NavLink>
          <NavLink to="/contact" className="menuItem"> Contact</NavLink>
          <NavLink to="/course-videos" className="menuItem"> Videos <span></span></NavLink>
          {
            localStorage.getItem("token") && localStorage.getItem("user")? <div className='roundedProfileDP'> Profile
            
            <div className={isHamMenuOpn?'innerItmsLog':"innerItmsLog rmvProfSub"}>
              <ul>
                <li><span className='loggedUsrStyle'>{loggedUserFetch.name}</span></li>
                <li onClick={()=>navigate('/change-password')}>Change Password</li>
                <li onClick={handleLogout}><button className="BtnStyleLinkLout">Logout </button></li>
              </ul>
            </div>
            
            </div>
            : <NavLink to="/login" className="menuItem">LOGIN</NavLink>
          }


        </div>
      </div>
    </nav>
  )
}

export default Header