import React, { useEffect, useState } from 'react'
import "../CSS/Contact.css"
import axios from 'axios'
import useTrackPageView from '../CustomHooks/useTrackPageView';


const Contact = ({ setProgress }: any) => {
  useTrackPageView('contact'); // Track page view for the About page

  useEffect(() => {
    setProgress(40);
    setTimeout(() => {
      setProgress(100);
    }, 500);
  }, [setProgress]);


  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setemail] = useState("");
  const [message, setMessage] = useState("");
  const [showSubmit, setShowSubmit] = useState({ message: "", isSent: false });

  const handleSendMail = () => {

    if (fname.length == 0 && lname.length == 0 && email.length == 0 && message.length == 0) {
      setShowSubmit({
        message: "All Fields are required",
        isSent: false
      });
    } else {
      axios.post('https://admin.wpskills.in/api/user/send-contact-mail', {
        fName: fname,
        lName: lname,
        email,
        message
      })
        .then(function (response) {
          if (response.data.status == "failed") {
            setShowSubmit({
              message: response.data.message,
              isSent: false
            });
          } else {
            setShowSubmit({
              message: response.data.message,
              isSent: true
            });
          }

        })
        .catch(function (error) {
          console.log(error);
        });

      setfname("");
      setlname("");
      setemail("");
      setMessage("");
    }

    setTimeout(function () {
      setShowSubmit({ message: "", isSent: false });
    }, 3000)
  }

  return (
    <div className='contactWrapper'>
      <div className="innerContWrapper">
        <div className="leftCont">
          <h1>Contact us</h1>
          <p>
            <b>Address</b>: Near Sebel Cinema Hall, Badarpur Delhi, PIN- 110044. <br />
            <b>Contact</b> : +91 8340-490-518
          </p>

          <p>
            Need to get in touch with us? Either fill out the form with your inquiry or find the department <a href="mailto:info@wpskills.in">Mail (info@wpskills.in)</a> you'd like to contact.
          </p>
        </div>
        <div className="rytCont">
          <div className="shadowBox">
            <div className="customRow">
              <div>
                <label htmlFor="fName">First Name*</label>
                <input type="text" id='fName' name='fName' value={fname} required onChange={(e) => setfname(e.target.value)} />
              </div>

              <div>
                <label htmlFor="lName">Last Name</label>
                <input type="text" id='lName' name='lName' value={lname} required onChange={(e) => setlname(e.target.value)} />
              </div>
            </div>

            <div className='newEntRow'>
              <label htmlFor="Email">Email*</label>
              <input type="text" id='Email' name='Email' value={email} required onChange={(e) => setemail(e.target.value)} />
            </div>

            <div className='newEntRow'>
              <label htmlFor="howHelp">What can we help you with?*</label>
              <textarea name="howHelp" id="howHelp" value={message} required onChange={(e) => setMessage(e.target.value)} ></textarea>
            </div>

            <div className="submit">
              <button id='submitFormData' onClick={handleSendMail}>Submit</button>
            </div>
            {showSubmit.message ? <div className={showSubmit.isSent ? "responseBox success" : "responseBox"}>{showSubmit.message}</div> : ""}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact