import React from 'react'
import "../CSS/PromoBnr1.css"

const PromoBnr1 = () => {
  return (
    <div className='MainWrappper'>
        <div className="item one">
            <p><span>500+</span> Enrollments</p>
        </div>
        <div className="item two">
            <p>Best for Beginners</p>
        </div>
        <div className="item three">
            <p>Why should you become a WP Developer?</p>
            <p>More than 70% of the websites in this world are powered with WordPress including sony.com</p>
        </div>
    </div>
  )
}

export default PromoBnr1