import React, {useEffect} from 'react'
import "../CSS/Refund.css"
import useTrackPageView from '../CustomHooks/useTrackPageView';


const Refund = ({setProgress}:any) => {
  useTrackPageView('refund'); // Track page view for the About page

  useEffect(() => {
    setProgress(40);
    setTimeout(() => {
      setProgress(100);
    }, 500);
  }, [setProgress]);

  return (  
    <div className='refundWrapper'>
      <p className="titleTxt">
        Refund Policy
      </p>

      <p className="commonPadding">
        Refunds can only be offered if you haven't received the course within 24 hours of making the purchase and only on your request. You can ask for a refund only after 24 hours of making the payment.
      </p>

      <p className="commonPadding">
          To request a refund, please contact us at refund@wpskills.in Please note that refunds will only be processed for purchases made directly through our website; purchases made through third-party sites are subject to their own refund policies. Additionally, please note that course material accessed or downloaded after purchase is non-refundable. We appreciate your understanding.
      </p>
    </div>
  )
}

export default Refund