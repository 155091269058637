import React,  {useEffect} from 'react'
import "../CSS/Terms.css"
import useTrackPageView from '../CustomHooks/useTrackPageView';

const Terms = ({setProgress}:any) => {
  useTrackPageView('terms'); // Track page view for the About page

  useEffect(() => {
    setProgress(40);
    setTimeout(() => {
      setProgress(100);
    }, 500);
  }, [setProgress]);

  return (
    <div className='termsWrapper'>
      <p className='titleTxt'>Terms & Conditions</p>

      <div className="sectionTitleContent">
        <p className="headingTxt">
          1. Introduction
        </p>
        <p className="descrTxt">
          Welcome to www.wpskills.in By using our website and purchasing our course, you agree to the following terms and conditions. Please read them carefully.
        </p>
      </div>

      <div className="sectionTitleContent">
        <p className="headingTxt">
          2. Use of Website & Course Content
        </p>
        <p className="descrTxt">
          You may only use this website and the course content for personal, non-commercial purposes. You may not distribute, modify, or create derivative works from the website or course content without our express written permission.
        </p>
      </div>

      <div className="sectionTitleContent">
        <p className="headingTxt">
          3. Purchases & Refunds
        </p>
        <p className="descrTxt">
          All purchases made through our website are final and non-refundable. If you are not satisfied with the course, please contact us within 7 days of purchase to discuss your options.
        </p>
      </div>

      <div className="sectionTitleContent">
        <p className="headingTxt">
          4. Disclaimer of Warranties & Liability
        </p>
        <p className="descrTxt">
          This website and course are provided “as is” without warranty of any kind, either expressed or implied. We disclaim all warranties, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, non-infringement, and title. In no event shall we be liable for any damages whatsoever, whether direct, indirect, special, punitive, or consequential, arising out of or in connection with the use of this website or course.
        </p>
      </div>

      <div className="sectionTitleContent">
        <p className="headingTxt">
          5. Changes to Terms & Conditions
        </p>
        <p className="descrTxt">
          We reserve the right to modify these terms and conditions at any time without prior notice. Any changes will be effective immediately upon posting to the website. Your continued use of the website and/or course following such changes constitutes your acceptance of the revised terms and conditions.
        </p>
      </div>

      <div className="sectionTitleContent">
        <p className="headingTxt">
          6. Governing Law
        </p>
        <p className="descrTxt">
          These terms and conditions shall be governed by and construed in accordance with the Indian laws, without regard to its conflict of law provisions.
        </p>
      </div>

      <div className="sectionTitleContent">
        <p className="headingTxt">
          7. Contact Us
        </p>
        <p className="descrTxt">
          If you have any questions about these terms and conditions, please contact us. Thank you for using our website and course!
        </p>
      </div>


    </div>
  )
}

export default Terms