import React from 'react'
import "../CSS/WorkAsCards.css"
import EntrollNowBtn from './EntrollNowBtn'
import useTrackPageView from '../CustomHooks/useTrackPageView';


const WorkAsCards = ({checkoutHandler,amount}:any) => {
  useTrackPageView('home'); // Track page view for the About page

  return (
    <>
    <div className='workAsWrapper'>
        <div className="workAsCrd">
          <div className="rw1"><i className="fa-solid fa-laptop"></i></div>
          <div className="rw2">Work as a Full-time Freelancer</div>
          <div className="rw3">Average monthly earnings of a fresher ranges between $200 - $3000 (Estimated) but totally depends on your skills</div>
        </div>

        <div className="workAsCrd">
          <div className="rw1"><i className="fa-solid fa-laptop"></i></div>
          <div className="rw2">Work as a Full-time Freelancer</div>
          <div className="rw3">Average monthly earnings of a fresher ranges between $200 - $3000 (Estimated) but totally depends on your skills</div>
        </div>

        <div className="workAsCrd">
          <div className="rw1"><i className="fa-solid fa-laptop"></i></div>
          <div className="rw2">Work as a Full-time Freelancer</div>
          <div className="rw3">Average monthly earnings of a fresher ranges between $200 - $3000 (Estimated) but totally depends on your skills</div>
        </div>

        <div className="workAsCrd">
          <div className="rw1"><i className="fa-solid fa-laptop"></i></div>
          <div className="rw2">Work as a Full-time Freelancer</div>
          <div className="rw3">Average monthly earnings of a fresher ranges between $200 - $3000 (Estimated) but totally depends on your skills</div>
        </div>
    </div>

    <EntrollNowBtn checkoutHandler={()=>checkoutHandler(amount)}/>
    <div className="threeBlockRow">
    <div className="itemBlock">
      <div className="icon"><i className="fas fa-hourglass-start"></i></div>
      <div className="textblock">
          Learn at your pace Pause lectures or repeat videos
      </div>
    </div>
    <div className="itemBlock">
      <div className="icon"><i className="fa-solid fa-handshake"></i></div>
      <div className="textblock">
      Get a helping hand Get support on call & WhatsApp
      </div>
    </div>
    <div className="itemBlock">
      <div className="icon"><i className="fa-solid fa-code"></i></div>
      <div className="textblock">No-Code Technology No technical knowledge needed.</div>
    </div>
</div>
</>
  )
}

export default WorkAsCards