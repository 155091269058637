import { useEffect, useState } from 'react'
import useTrackPageView from '../CustomHooks/useTrackPageView';

const CourseAccordion = ({ vId,allVideos}: any) => {
    useTrackPageView('Purchased Video Section'); 

    const [syllabusData, setsyllabusData] = useState([{
        id: 1,
        title: "Module #1 - Getting Started",
        subTopics: [
            {
                innerId: 0,
                vidTxt: "Welcome to the course",
                vidId: "HphUvEI5z5b5ru3YGuoMNB39ALFyEuqw"
            }
        ]
    }]);
    
    useEffect(() => {

        const getVideoData = async () => {
            setsyllabusData(allVideos)
        }

        getVideoData();
    }, [allVideos])

    const [selected, setSelected] = useState(0);
    const toggle = (i: any) => {
        selected === i ? setSelected(0) : setSelected(i);
    }
    return (
        <>
            {syllabusData.length > 1 ? <div className='courseSyllabusWrapper'>
                <div className="corsAccord">
                    {syllabusData.map((item, i) => (
                        <div className='item' key={item.id}>
                            <div className="title" onClick={() => toggle(i)}>
                                <h2><span><i className={`fa-solid ${selected === i ? "fa-chevron-up rotate" : "fa-chevron-up"}`}></i></span> {item.title}</h2>
                            </div>
                            <div className={selected === i ? "content show" : "content"}>{item.subTopics.map((inneritem, i) => (<div className='wrapOneLine' key={i}><span><i className="fa-solid fa-display"></i></span> <p key={i} onClick={() => vId(inneritem.vidId, inneritem.vidTxt, inneritem.innerId)}>{inneritem.vidTxt}</p></div>))}</div>
                        </div>
                    ))}
                </div>
            </div> : <h3>Loading...</h3>}
        </>

    )
}

export default CourseAccordion