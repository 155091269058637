import React, { useEffect } from 'react'
import useTrackPageView from '../CustomHooks/useTrackPageView';
import "../CSS/PageNotFound.css"

export const Pagenotfound = ({setProgress}:any) => {
  useTrackPageView('page not found'); // Track page view for the About page

    useEffect(() => {
        setProgress(40);
        setTimeout(() => {
          setProgress(100);
        }, 500);
    }, []);
    
  return (
    <div className='pageNtFoundWrapper'>
      <h1>404</h1>
      <img src="https://cdn-icons-png.flaticon.com/512/2748/2748558.png" alt="Page Not Found"/>
    </div>
  )
}
