import React from 'react'

import "../CSS/SyllabusGiftsSection.css"
import EntrollNowBtn from './EntrollNowBtn'

const SyllabusGiftsSection = ({checkoutHandler,amount}:any) => {
  return (
    <div className='slbsGiftWrapper'>
      <EntrollNowBtn checkoutHandler={()=>checkoutHandler(amount)}/>
      <div className="syllabus">
        <p className='slbsTitle'>Course Syllabus - 28 Lectures</p>
        <div className="syllabusDescr">
          <p className="moduleTitle">Module #1 - Getting Started</p>
          <ul>
            <li>Welcome to the course</li>
            <li>Register for Zoom Doubt Class</li>
            <li>Lecture 1: What is a Website & Why People spend thousands for a Website?</li>
          </ul>
        </div>

        <div className="syllabusDescr">
          <p className="moduleTitle">Module #2 - Domain and Hosting</p>
          <ul>
            <li>Lecture 2: What is a Domain Name and Web Hosting?</li>
            <li>Lecture 3- How to buy Web Hosting and a Free Domain Name</li>
            <li>Gifts available for Downloading</li>
            <li>Lecture 4- Understanding cPanel and Installing WordPress</li>
            <li>Lecture 5- Creating a Business Email.</li>
          </ul>
        </div>

        <div className="syllabusDescr">
          <p className="moduleTitle">Module #3 - WordPress Environment</p>
          <ul>
            <li>Lecture 6: WordPress themes and plugins</li>
            <li>Lecture 7: How to Install Themes & Plugins</li>
            <li>Lecture 8: Creating Webpages and Menu</li>
            <li>Lecture 9: WordPress Basic Settings</li>
          </ul>
        </div>

        <div className="syllabusDescr">
          <p className="moduleTitle">Module #4 - Making the Homepage</p>
          <ul>
            <li>Lecture 10: Understanding Sections, Heading, Image, Button, Text Editor Elements, etc.</li>
            <li>Lecture 11: Understanding Columns and Creating Box Shadow Hover Effect</li>
            <li>Lecture 12: How to Duplicate Content and use Inspect Element Feature</li>
            <li>Lecture 13: What is Image Box Element?</li>
            <li>Lecture 14: Creating the Testimonials Section</li>
          </ul>
        </div>

        <div className="syllabusDescr">
          <p className="moduleTitle">Module #5 - Making the About Us Page</p>
          <ul>
            <li>Lecture 15: Creating About us Page</li>
          </ul>
        </div>

        <div className="syllabusDescr">
          <p className="moduleTitle">Module #6 - Making the Services Page</p>
          <ul>
            <li>Lecture 16: How to Create the Services Page</li>
          </ul>
        </div>

        <div className="syllabusDescr">
          <p className="moduleTitle">Module #7 - How to get clients?</p>
          <ul>
            <li>Lecture 17: Contact Page - Embedding Google Map</li>
            <li>Lecture 18: Contact Page - Creating Inquiry Form</li>
            <li>Lecture 19: Contact Page - Putting Contact Details</li>
            <li>Lecture 20: Creating Blog Posts</li>
            <li>Lecture 21: How to Design Logo Without Photoshop</li>
            <li>Lecture 22: Creating the Custom Header</li>
            <li>Lecture 23: How to Make a Mobile Responsive Header</li>
            <li>Lecture 24: Creating the Custom Footer</li>
            <li>Lecture 25: Adding Animations to Elements</li>
            <li>Lecture 26: How to Create a Banner Slider</li>
            <li>Lecture 27: What is Freelancing and How to Get Clients?</li>
            <li>Lecture 28: I got 4 Live Projects through Freelancing.</li>
          </ul>
        </div>


      </div>

      <div className="gifts">
        <div className="giftBox">
          <i className="fa-solid fa-gift"></i>
          <p className='excGift'>EXCLUSIVE GIFTS</p>
          <p>Get Access to 6 Premium Themes & Plugins</p>
        </div>

        <div className="excellentReview">
          <p className="reviewTxt"><span className="excellent" style={{color:"#3075F2"}}>Excellent</span> Based on 128 reviews</p>
          <div className="imgReview"></div>
        </div>

        <div className="userReviewCard">
          <div className="row1">
            <div className="profilePic profilePic6"></div>
            <div className="usrNameDate">
              <p className="name">Rakesh Yadav</p>
              <p className="datePosted">8. January, 2024.</p>
            </div>
            <div className="googleLogo"></div>
          </div>
          <div className="row2">
            <span className="startCount">
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
            </span>
          </div>
          <div className="row3">
            <p>
            Detailed Explanation of course from beginners to advance and quick support on call, WhatsApp and email. <br /> thanks sir.</p>
          </div>
        </div>

        <div className="userReviewCard">
          <div className="row1">
            <div className="profilePic profilePic5"></div>
            <div className="usrNameDate">
              <p className="name">Shyamal Singh</p>
              <p className="datePosted">2. January, 2024.</p>
            </div>
            <div className="googleLogo"></div>
          </div>
          <div className="row2">
            <span className="startCount">
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
            </span>
          </div>
          <div className="row3">
            <p>The course 14 days WordPress and Mastery Course is too excellent teach by Rajnish Sir and it is very easy to understand for beginner level as well as for advanced level. The way he has explained and covered almost all things with live examples is superb and at the end you can build your own websites and start earning......!!</p>
          </div>
        </div>

        <div className="userReviewCard">
          <div className="row1">
            <div className="profilePic profilePic1"></div>
            <div className="usrNameDate">
              <p className="name">Manoj Kumbhar</p>
              <p className="datePosted">10. December, 2023.</p>
            </div>
            <div className="googleLogo"></div>
          </div>
          <div className="row2">
            <span className="startCount">
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
            </span>
          </div>
          <div className="row3">
            <p>Great Learning experience in this course...</p>
          </div>
        </div>

        <div className="userReviewCard">
          <div className="row1">
            <div className="profilePic profilePic2"></div>
            <div className="usrNameDate">
              <p className="name">Kartik Thakur</p>
              <p className="datePosted">2. December, 2023.</p>
            </div>
            <div className="googleLogo"></div>
          </div>
          <div className="row2">
            <span className="startCount">
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
            </span>
          </div>
          <div className="row3">
            <p>Best course and best guru! No doubt if you will buy this course it can change your life and you can't even imagine hiw much this course can help you. Best thing about sir is he never goes backwards to help his student. At last all i wanna say is thank you soo much sir for giving us such great knowledge and changing our lives.</p>
          </div>
        </div>

        <div className="userReviewCard">
          <div className="row1">
            <div className="profilePic profilePic3"></div>
            <div className="usrNameDate">
              <p className="name">Amit Prasad</p>
              <p className="datePosted">12. November, 2023.</p>
            </div>
            <div className="googleLogo"></div>
          </div>
          <div className="row2">
            <span className="startCount">
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
            </span>
          </div>
          <div className="row3">
            <p>The course 14 days WordPress and Mastery Course is too excellent teach by Rajnish Sir and it is very easy to understand for beginner level as well as for advanced level. The way he has explained and covered almost all things with live examples is superb and at the end you can build your own websites and start earning......!!</p>
          </div>
        </div>

        <div className="userReviewCard one">
          <div className="row1">
            <div className="profilePic profilePic4"></div>
            <div className="usrNameDate">
              <p className="name">Yogesvara (Yogi)</p>
              <p className="datePosted">29. November, 2022.</p>
            </div>
            <div className="googleLogo"></div>
          </div>
          <div className="row2">
            <span className="startCount">
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
              <i className="fa-solid fa-star"></i>
            </span>
          </div>
          <div className="row3">
            <p>You are a fantastic person & you are a life saver. Your lectures helped me to find my dream career. Lot of good wishes & wishing you good health & peace sir. Subka mangal ho.</p>
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default SyllabusGiftsSection