import React, { useEffect, useState } from 'react'
import "../CSS/Login.css"
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { loginSchema } from '../FormValidationSchema';
import { useSelector } from 'react-redux';
import useTrackPageView from '../CustomHooks/useTrackPageView';

const Login = ({ setProgress }: any) => {
  useTrackPageView('login'); // Track page view for the About page
  
  const { state } = useLocation();
  const initialValues = {
    Email: state ? state.email : "",
    Password: state ? state.password : "",
    accTC: false
  }

  const [loading, setloading] = useState(false);
  const [loginStat, setloginStat] = useState<any>({});
  const navigate = useNavigate();


  const [SERVER_APP_URL, SET_SERVER_APP_URL] = useState<String>("");
  const [CLIENT_APP_URL, SET_CLIENT_APP_URL] = useState<String>("");
  const app_host_status = useSelector((state: any) => state.urlStateReducer);

  useEffect(() => {
    if (app_host_status) {
      SET_SERVER_APP_URL(app_host_status.SERVER_URL);
      SET_CLIENT_APP_URL(app_host_status.CLIENT_URL);
    }
  }, [SERVER_APP_URL, CLIENT_APP_URL])

  const { values, errors, handleChange, handleSubmit, handleBlur, touched } = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, action) => {

      setloading(true);
      let result = fetch(`${SERVER_APP_URL}/api/user/login`, {
        method: 'POST',
        body: JSON.stringify({ email: values.Email, password: values.Password }),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      let response = await (await result).json();

      setloginStat(response);

      if (response.status === "failed") {
        document.querySelector('.showLoginStats')?.classList.remove("successGreen");
        setloading(false);
      }

      if (response.status === "success") {
        document.querySelector('.showLoginStats')?.classList.add("successGreen");
        localStorage.setItem("token", response.token);
        navigate("/course-videos");
        setloading(false);
      }

      action.resetForm();
    }

  });

  useEffect(() => {
    setProgress(40);
    setTimeout(() => {
      setProgress(100);
    }, 500);

    let isUser = localStorage.getItem('user');
    if (isUser) {
      navigate('/course-videos');
    }
  }, [setProgress]);

  const [showPassword, setShowPassword] = useState(false);
  const setShowHide = () => {
    setShowPassword(!showPassword);
  }

  return (
    <div className='LoginWrapper'>
      <div className="innerLoginBox">
        <h2>Login</h2>
        <p className='heyEnter'>Hey enter your details to sign in to your account</p>
        <form onSubmit={handleSubmit} id='LoginToDash'>
          <div className="formFieldBox">
            <div className="inputRow">
              <span><i className="fa-solid fa-user"></i></span>
              <input type="text" name='Email' placeholder='Email' id='username' onChange={handleChange} onBlur={handleBlur} value={values.Email} />
              {errors.Email && touched.Email ? <p className='formField-error'>{errors.Email}</p> : null}
            </div>

            <div className="inputRow">
              <span><i className="fa-solid fa-lock"></i></span>
              <input name='Password' type={showPassword ? "text" : "password"} placeholder='Password' id='password' onChange={handleChange} onBlur={handleBlur} value={values.Password} />
              <label onClick={setShowHide}><i className={showPassword ? "fas fa-eye" : "fas fa-eye-slash"}></i></label>
              {errors.Password && touched.Password ? <p className='formField-error'>{errors.Password}</p> : null}
            </div>
            {loginStat.message ? <div className="showLoginStats" style={{ 'display': 'block' }}>{loginStat.message}</div> : <div className="showLoginStats" style={{ 'display': 'none' }}>{loginStat.message}</div>}
            <div className='TCMainCont' style={{ 'display': 'flex', 'justifyContent': 'space-between', 'position': 'relative' }}>
              <div className='TncContainer'>
                <input type="checkbox" name='accTC' checked={values.accTC} id='acceptTC' onChange={handleChange} /> &nbsp;
                <label htmlFor='acceptTC' className='tNcMessage'>By clicking this box you agree to the <Link to="/terms">terms and conditions</Link></label>
              </div>
              <span className='forgotPwdCont' style={{ 'cursor': 'pointer', 'color': '#3EAF57' }} onClick={() => navigate('/forget-password')}>Forgot Password?</span>
              {errors.accTC && touched.accTC ? <p className='formField-error'>{errors.accTC}</p> : null}
            </div>
            <div>
              <input type='submit' className='LoginBtn' value={loading ? "Logging.." : "Login"} />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login