import * as Yup from 'yup'

export const loginSchema = Yup.object({
    Email: Yup.string().email().min(4).max(50).required("Please enter email"),
    // Password: Yup.string().min(6).max(50).required("Please enter Password"),
    Password: Yup.string().max(50).required("Please enter Password"),
    accTC: Yup.boolean().oneOf([true],"You must accept the terms and conditions")
});

export const registerSchema = Yup.object({
    Name: Yup.string().min(2).max(50).required("Please enter Name"),
    Email: Yup.string().email().min(4).max(50).required("Please enter email"),
    Password: Yup.string().min(6).max(50).required("Please enter Password"),
    Confirm_Password: Yup.string().required().oneOf([Yup.ref("Password")],'Passwords must match'),
    ReferenceNum: Yup.string().required("Something went wrong with your order reference number"),
});

export const changePasswordSchema = Yup.object({
    Password: Yup.string().min(6).max(50).required("Please enter Password"),
    Confirm_Password: Yup.string().required().oneOf([Yup.ref("Password")],'Passwords must match')
})

export const forgetPasswordSchema = Yup.object({
    Email: Yup.string().email().min(4).max(50).required("Please enter email"),
})

