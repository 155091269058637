import React, { useEffect, useState } from 'react'
import "../CSS/AllVideos.css"
import CourseAccordion from './CourseAccordion';
import { useDispatch, useSelector } from 'react-redux';
import { getVideos } from "../Redux/videoAction";
import { getLoggedUserData } from "../Redux/loggedUserAction";

import { useNavigate } from 'react-router-dom';
import EntrollNowBtn from './EntrollNowBtn';
import useCountDown from 'react-countdown-hook';
import useTrackPageView from '../CustomHooks/useTrackPageView';

function add(accumulator: any, a: any) {
  return accumulator + a;
}

const AllVideos = ({ setProgress, checkoutHandler, amount }: any) => {
  useTrackPageView('course-videos'); // Track page view for the About page

  const navigate = useNavigate();

  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();

  const videoData = useSelector((state: any) => state.videosData);
  const loggedUserData = useSelector((state: any) => state.loggedUserData);
  // const HamMenuState = useSelector((state: any) => state.menuStateReducer);

  const initialTime = 60 * 1000;
  const interval = 1000;
  const [timeLeft, { start, pause, resume, reset }] = useCountDown(initialTime, interval);
  // pause();
  // console.log(HamMenuState);
  // console.log(timeLeft/1000);

  if (loggedUserData.status == "failed") {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  }

  if (loggedUserData.courseStatus === "Expired") {
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    navigate("/login");
  }

  let subSectionCount;
  if (videoData.length > 0) {
    let arrOfSubCourseItm = videoData.map((x: { subTopics: string | any[]; }) => x.subTopics.length);
    subSectionCount = arrOfSubCourseItm.reduce(add, 0);
  }

  const handleTabVisibilityChange = () => {
    if (document.visibilityState !== 'visible') {
      pause();
    } else {
      if (localStorage.getItem("token")) {
        updateExpireTime();
      }
    }
  };

  useEffect(() => {
    setloading(true);

    setProgress(40);
    setTimeout(() => {
      setProgress(100);
    }, 500);

    dispatch(getVideos());
    dispatch(getLoggedUserData());
  }, []);

  useEffect(() => {
    setloading(false);
  }, [videoData.length]);


  // check for user activity starts
  setTimeout(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login");
  }, 17999998);

  const updateExpireTime = () => {
    start(45 * 60 * 1000);
  }

  if (localStorage.getItem("token") && timeLeft / 1000 == 1) {
    // updateExpireTime();

    localStorage.removeItem("token");
    localStorage.removeItem("user");
    // pause();
    navigate("/login");
    window.location.reload();
  }


  useEffect(() => {

    if (localStorage.getItem("token")) {
      updateExpireTime();

      window.addEventListener('click', updateExpireTime)
      window.addEventListener('keypress', updateExpireTime)
      window.addEventListener('scroll', updateExpireTime)
      window.addEventListener('mousemove', updateExpireTime)
      document.addEventListener('visibilitychange', handleTabVisibilityChange);

      return () => {
        window.removeEventListener('click', updateExpireTime);
        window.removeEventListener('keypress', updateExpireTime);
        window.removeEventListener('scroll', updateExpireTime);
        window.removeEventListener('mousemove', updateExpireTime);
      }
    }

  }, [])
  // check for user activity ends



  const [videoTitle, setVideoTitle] = useState("");
  const [link, setLink] = useState("https://fast.wistia.net/embed/iframe/l8yu2fwlad");
  const [isPlaying, setIsPlaying] = useState(false);

  // useEffect(() => {
  //   // check Wistia API data: video playing or puase starts
  //   const parts = link.split('/');
  //   let videoHash = parts[parts.length - 1];

  //   // @ts-ignore
  //   const videoEmbed: any = window?.Wistia?.api(videoHash);

  //   if (videoEmbed) {
  //     videoEmbed.bind('play', () => {
  //       setIsPlaying(true);
  //       console.log('The video is playing');
  //     });

  //     videoEmbed.bind('pause', () => {
  //       setIsPlaying(false);
  //       console.log('The video is paused');
  //     });

  //     videoEmbed.bind('end', () => {
  //       setIsPlaying(false);
  //       console.log('The video has ended');
  //     });
  //   }

  //   // check Wistia API data: video playing or puase ends

  // }, [link])



  const getVideoSelected = (gotId: string, videoTitle: string, index: any) => {

    let combinedLink = `https://fast.wistia.net/embed/iframe/${gotId}`;
    setLink(combinedLink);
    setVideoTitle(videoTitle);

    document.querySelectorAll<HTMLElement>(".content p").forEach((elem, i) => {
      elem.classList.remove("activeVideo");
    });
    document.querySelectorAll<HTMLElement>(".content p")[index].classList.add("activeVideo");
  };

  return (
    <div className='courseVideoWrapper'>
      {
        localStorage.getItem("token") && timeLeft / 1000 <= 20 ? <div className="inactivityChkTimer">Seems Inactive, Logging-Out in&nbsp;<span>{timeLeft / 1000} </span>&nbsp;seconds.</div> : null
      }

      {
        videoData.type == "FETCH_VIDEOS_FAILED" && !localStorage.getItem("token") ? <div className='pleaseLogin'>
          If you have purchased the course, Please Login to get access of course videos or Enroll Now<br /> <button className="loginRedir" onClick={() => navigate("/login")}>Login</button> <br />
          <EntrollNowBtn checkoutHandler={() => checkoutHandler(amount)} />
        </div> : loading ? <div style={{ "height": "100%", "width": "100%", "display": "flex", "alignItems": "center", "justifyContent": "center" }}>Loading...</div> : <div className="innerWrapperSyllVideo">
          <div className="accordionSec">
            <h3>Course content</h3>
            <div className="countSectionrow">
              <div className="left"><span className='sectionCount'>{videoData.length} sections</span> • <span className='lectureCount'>{subSectionCount} lectures</span></div>
              <div className="ryt"><u>Expand all sections</u></div>
            </div>
            <CourseAccordion vId={getVideoSelected} allVideos={videoData} />
          </div>
          <div className="videoSection">
            <h3>{videoTitle ? videoTitle : videoData[0] && videoData[0].subTopics[0].vidTxt}</h3>
            <div className={`wistia_embed wistia_async_${videoData[0] && videoData[0].subTopics[0].vidId} videoFoam=true`} style={{ left: 0, width: '100%', height: 0, position: 'relative', paddingBottom: '56.25%' }}>
              <figure style={{ left: 0, width: '100%', height: 0, position: 'relative', paddingBottom: '56.25%', marginBlockEnd: 0, marginBlockStart: 0, marginInlineStart: 0, marginInlineEnd: 0 }}>
                {videoData.length > 1 ? <iframe
                  src={link ? link : `https://fast.wistia.net/embed/iframe/${videoData[0] && videoData[0].subTopics[0].vidId}`}
                  scrolling="no"
                  style={{ border: 0, top: 0, left: 0, width: '100%', height: '100%', position: 'absolute', overflow: 'hidden', borderRadius: '0' }}
                  allowFullScreen={true}
                  allow="autoplay"
                  title='intro video'
                ></iframe> : "Loading.."}
              </figure>
            </div>
          </div>
        </div>
      }




    </div>
  )
}

export default AllVideos