import React from 'react'
import "../CSS/PromoBnr2.css"
import EntrollNowBtn from './EntrollNowBtn'
const PromoBnr2 = ({checkoutHandler,amount}:any) => {
  return (
    <div className='promoBannerWrapper'>
        <p className='heading'>Start investing your free time for future, <span className='Et'>enroll today!</span></p>
        <div className="innerBiwrapper">
          <div className="left">
            <p>Access includes</p>
            <ul>
                <li>25+ detailed video lectures</li>
                <li>1 Year access of video lectures</li>
                <li>Get Course Certificate</li>
                <li>6 Premium Gifts Included</li>
            </ul>
          </div>
          <div className="ryt">
            <EntrollNowBtn checkoutHandler={()=>checkoutHandler(amount)}/>
          </div>
        </div>
    </div>
  )
}

export default PromoBnr2