import React from 'react'

import '../CSS/HappyStudents.css'
import HappyStudentSlides from './HappyStudentSlides'

const HappyStudents = () => {

  return (
    <div className='happyStudentsWrapper'>
        <h1 className='Title1'>500+ Happy Students</h1>
        <HappyStudentSlides/>
    </div>
  )
}

export default HappyStudents