import React from 'react'
import "../CSS/WhtLearn14Dys.css"

const WhtLearn14Dys = () => {
  return (
    <div className='wrapper'>
            <div className="leftSection">
                <div className="customRow">
                    <div className="icon"><i className="fa-solid fa-clock"></i></div>
                    <div className="txt">3 Months Course Validity</div>
                </div> 
                
                <div className="customRow">
                    <div className="icon"><i className="fa-solid fa-video"></i></div>
                    <div className="txt">25+ Video Lectures 24 hours access</div>
                </div> 
                
                <div className="customRow">
                    <div className="icon"><i className="fa-solid fa-language"></i></div>
                    <div className="txt">Hindi (हिंदी में) Course Language</div>
                </div> 
                
                <div className="customRow">
                    <div className="icon"><i className="fa-solid fa-gift"></i></div>
                    <div className="txt">6 Gifts In the course</div>
                </div>
                
                <div className="customRow">
                    <div className="icon"><i className="fa-solid fa-headset"></i></div>
                    <div className="txt">Support On Call & Email</div>
                </div>
            </div>
            <div className="rytSection">
                <p>What will you learn in this 14 Days WordPress Course?</p>
                <ul>
                    <li>How to design & develop websites without coding knowledge.</li>
                    <li>How to buy a domain name and web hosting.</li>
                    <li>How to create pages and menu easily.</li>
                    <li>How to embed Google Maps on your website.</li>
                    <li>How to add animations to your webpages.</li>
                    <li>How to create inquiry forms.</li>
                    <li>How to create backup of your website.</li>
                    <li>How to launch your website on the internet with just a few steps.</li>
                    <li>How to start your own home-based web design business.</li>
                </ul>
            </div>
    </div>
  )
}

export default WhtLearn14Dys