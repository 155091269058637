import React, { useEffect, useState } from 'react'
import "../CSS/Payment.css"
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik';
import { registerSchema } from '../FormValidationSchema';
import useCountDown from 'react-countdown-hook';
import { useSelector } from 'react-redux';
import useTrackPageView from '../CustomHooks/useTrackPageView';

const PaymentSuccess = () => {
  useTrackPageView('payment success'); // Track page view for the About page

  const params = useParams();
  const referenceNum = params.reference;
  const navigate = useNavigate();
  const initialValues = {
    Name: "",
    Email: "",
    Password: "",
    Confirm_Password: "",
    accTC: false,
    ReferenceNum: referenceNum,
  }

  const [SERVER_APP_URL, SET_SERVER_APP_URL] = useState<String>("");
  const [CLIENT_APP_URL, SET_CLIENT_APP_URL] = useState<String>("");
  const app_host_status = useSelector((state: any) => state.urlStateReducer);

  useEffect(() => {
    if (app_host_status) {
      SET_SERVER_APP_URL(app_host_status.SERVER_URL);
      SET_CLIENT_APP_URL(app_host_status.CLIENT_URL);
    }
  }, [SERVER_APP_URL, CLIENT_APP_URL])

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setloading] = useState(false);
  const [loginStat, setloginStat] = useState<any>({});

  const [timer, setTimer] = useState<boolean>(false);

  const setShowHide = () => {
    setShowPassword(!showPassword);
  }

  const interval = 1000;
  const [timeLeft, { start, pause, resume, reset }] = useCountDown(6000, interval);
  useEffect(() => {
    pause()
  }, [])


  const { values, errors, handleChange, handleSubmit, handleBlur, touched } = useFormik({
    initialValues,
    validationSchema: registerSchema,
    onSubmit: async (values, action) => {
      setloading(true);

      let result = fetch(`${SERVER_APP_URL}/api/user/register/${params.token}`, {
        method: 'POST',
        body: JSON.stringify({ name: values.Name, email: values.Email, password: values.Password, password_confirmation: values.Confirm_Password, tc: true, referenceNum: values.ReferenceNum }),
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${params.token}`
        }
      });

      let response = await (await result).json();
      setloginStat(response);
      if (response.status === "failed") {
        setloading(false);
        document.querySelector('.showLoginStats')?.classList.remove("successGreen");
        action.resetForm();
      }
      if (response.status === "Success") {
        setTimer(true);
        start(5000);
        setloading(false);
        document.querySelector('.showLoginStats')?.classList.add("successGreen");
      }
    }

  });
  if (timeLeft / 1000 == 1) {
    start(5000)
    navigate("/login", { state: { email: values.Email, password: values.Password } });
    window.location.reload();
  }
  return (
    <div className="paymentSuccesswrapper">
      <div className='successContainer'>
        <div className="successMark">
          <i className="fas fa-check-circle"></i>
        </div>
        <h3>ORDER SUCCESSFUL</h3>
        <p><span>Reference Number:</span> {referenceNum}</p>
        <p className='ifIssueDescr'>
          Please provide this Reference Number in case you face any Payment/Registration/Login issue
        </p>
      </div>
      <div className="registerForm">
        <h2>Create Account</h2>
        <p className='heyEnter'>Hey, Create account to get login access</p>
        <form id='regdForm' onSubmit={handleSubmit}>
          <div className='inputRow'>
            <span><i className="fa-solid fa-user"></i></span>
            <input type="text" name="Name" placeholder='Name' onChange={handleChange} onBlur={handleBlur} value={values.Name} />
            {errors.Name && touched.Name ? <p className='formField-error'>{errors.Name}</p> : null}
          </div>
          <div className="inputRow">
            <span><i className="fa-solid fa-envelope"></i></span>
            <input type="email" name="Email" placeholder='Email' onChange={handleChange} onBlur={handleBlur} value={values.Email} />
            {errors.Email && touched.Email ? <p className='formField-error'>{errors.Email}</p> : null}
          </div>
          <div className="inputRow">
            <span><i className="fa-solid fa-lock"></i></span>
            <input type={showPassword ? "text" : "password"} name="Password" placeholder='Password' onChange={handleChange} onBlur={handleBlur} value={values.Password} />
            <label onClick={setShowHide}><i className={showPassword ? "fas fa-eye" : "fas fa-eye-slash"}></i></label>
            {errors.Password && touched.Password ? <p className='formField-error'>{errors.Password}</p> : null}
          </div>
          <div className="inputRow">
            <span><i className="fa-solid fa-lock"></i></span>
            <input type='password' name="Confirm_Password" placeholder='Password Confirmation' onChange={handleChange} onBlur={handleBlur} value={values.Confirm_Password} />
            {errors.Confirm_Password && touched.Confirm_Password ? <p className='formField-error'>{errors.Confirm_Password}</p> : null}
          </div>
          <input type="hidden" name='refNum' value={referenceNum ? referenceNum : ""} />
          {loginStat.message ? <div className="showLoginStats" style={{ 'display': 'block' }}>{loginStat.message}</div> : <div className="showLoginStats" style={{ 'display': 'none' }}>{loginStat.message}</div>}
          <div style={{ position: "relative" }}>
            <input type="submit" value={loading ? "Registering.." : "Register"} className="LoginBtn" />
            {errors.ReferenceNum ? <p className='formField-error refError'>{errors.ReferenceNum}</p> : null}
          </div>
          {
            timer && <div className="redirect2Login">
              <p>Redirecting to login in {timeLeft / 1000} seconds. <Link to="/login">Redirect Now</Link></p>
            </div>
          }

        </form>
      </div>
    </div>
  )
}

export default PaymentSuccess