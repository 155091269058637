import React, { useEffect } from 'react'
import '../CSS/Shipping.css'
import useTrackPageView from '../CustomHooks/useTrackPageView';

const Shipping = ({ setProgress }: any) => {
    useTrackPageView('shipping'); // Track page view for the About page

    useEffect(() => {
        setProgress(40);
        setTimeout(() => {
            setProgress(100);
        }, 500);
    }, [setProgress]);

    return (
        <div className='refundWrapper'>
            <p className="titleTxt">
                Shipping & Delivery Policy
            </p>

            <p className="commonPadding">
            At WPskills.in, we specialize in offering high-quality online courses for WordPress web development. Please note that we do not deliver any physical items as part of our course offerings. Our courses are entirely digital and accessible online, providing you with the flexibility to learn at your own pace from anywhere in the world.
            </p>

            <p className="commonPadding">
            It's important to understand that RAJNISH KUMAR, the founder of WPskills.in, is not liable for any delivery claims or delays since our courses are delivered digitally. This means you can enjoy seamless access to our course materials without worrying about shipping or delivery issues. Dive into our comprehensive curriculum, gain valuable skills, and embark on your journey to becoming a proficient WordPress developer. Join us today and take your web development skills to new heights!
            </p>
        </div>
    )
}

export default Shipping