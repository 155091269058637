import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import '../CSS/PageVisit.css'; // Import CSS file
import { useSelector } from 'react-redux';

interface Visit {
  _id: string;
  page: string;
  count: number;
  ipAddress: string;
  formattedDate: string;
  visitTime: string;
  dayOfWeek: string;
  browser: { name: string; version: string; major: string };
  os: { name: string; version: string };
  ipDetails: {
    ip: string;
    city: string;
    region: string;
    country: string;
    loc: string;
    org: string;
    postal: string;
    timezone: string;
    readme: string;
  };
}

interface ApiResponse {
  visits: Visit[];
  ipAddress: string;
}

const WebsiteVisitDetails: React.FC = () => {


  const [SERVER_APP_URL, SET_SERVER_APP_URL] = useState<String>("");
  const [CLIENT_APP_URL, SET_CLIENT_APP_URL] = useState<String>("");
  const app_host_status = useSelector((state: any) => state.urlStateReducer);

  useEffect(() => {
    if (app_host_status) {
      SET_SERVER_APP_URL(app_host_status.SERVER_URL);
      SET_CLIENT_APP_URL(app_host_status.CLIENT_URL);
    }
  }, [SERVER_APP_URL, CLIENT_APP_URL])

  const [visitList, setVisitList] = useState<Visit[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [visitsPerPage] = useState<number>(10); // Show 5 data entries per page

  const [pieChartData, setPieChartData] = useState<{ x: string; y: number }[]>([]);
  const [pieChartOptions, setPieChartOptions] = useState<any>({
    labels: [],
    colors: ['#007bff', '#3EAF57', '#FFC933', '#3EAF57', '#FF9F33'],
  });

  const fetchData = async () => {
    try {
      setLoading(true);
      // console.log(SERVER_APP_URL)
      const response = await axios.get<ApiResponse[]>(`${SERVER_APP_URL}/pageviews/get-visits`);
      const newVisits = response.data.flatMap(item => item.visits);
      const sortedVisits = newVisits.sort((a, b) => {
        const dateA = new Date(a.formattedDate + ' ' + a.visitTime);
        const dateB = new Date(b.formattedDate + ' ' + b.visitTime);
        return dateB.getTime() - dateA.getTime(); // Sorted by newest to oldest
      });
      setVisitList(sortedVisits);

      // Prepare data for pie chart
      const pageCounts = newVisits.reduce((acc: { [key: string]: number }, visit: Visit) => {
        acc[visit.page] = (acc[visit.page] || 0) + visit.count;
        return acc;
      }, {});
      const pieData = Object.keys(pageCounts).map(page => ({ x: page, y: pageCounts[page] }));
      setPieChartData(pieData);
      setPieChartOptions({ ...pieChartOptions, labels: pieData.map(data => data.x) });
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [SERVER_APP_URL]); // Fetch data on component mount

  const handleRefresh = () => {
    fetchData();
  };

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const indexOfLastVisit = currentPage * visitsPerPage;
  const indexOfFirstVisit = indexOfLastVisit - visitsPerPage;
  const currentVisits = visitList.slice(indexOfFirstVisit, indexOfLastVisit);

  const chartOptions = {
    chart: {
      id: 'area-chart',
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: currentVisits.map(visit => `${visit.formattedDate} ${visit.visitTime}`),
    },
    yaxis: {
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: '#3EAF57', // Color for the area series
      },
      labels: {
        style: {
          colors: '#3EAF57', // Color for the area series
        },
      },
      title: {
        text: 'Area Series',
        style: {
          color: '#3EAF57', // Color for the area series
        },
      },
    },
    colors: ['#3EAF57'], // Set the color for the area series
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: ['#FFC933'], // Gradient color for the area series
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.8,
        stops: [0, 100],
      },
    },
  };

  const chartSeries = [
    {
      name: 'Area Series',
      type: 'area',
      data: currentVisits.map(visit => ({
        x: `${visit.formattedDate} ${visit.visitTime}`,
        y: visit.count,
        z: visit.ipDetails && visit.ipDetails.city ? visit.ipDetails.city.length : 0, // Check if ipDetails and city exist
      })),
    },
  ];


  const renderPagination = () => {
    if (!visitList || visitList.length === 0) {
      return null; // Return null if visitList is null, undefined, or empty
    }

    const pageNumbers = Math.ceil(visitList.length / visitsPerPage);

    if (pageNumbers <= 1) {
      return null;
    }

    const visiblePages = [];
    let startPage = 1;
    let endPage = pageNumbers;

    if (pageNumbers > 10) {
      if (currentPage <= 5) {
        endPage = 10;
      } else if (currentPage >= pageNumbers - 4) {
        startPage = pageNumbers - 9;
      } else {
        startPage = currentPage - 4;
        endPage = currentPage + 5;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      visiblePages.push(
        <button
          key={i}
          onClick={() => paginate(i)}
          className={currentPage === i ? 'active' : ''}
        >
          {i}
        </button>
      );
    }

    if (pageNumbers > 10) {
      if (currentPage > 5) {
        visiblePages.unshift(
          <button key="first" onClick={() => paginate(1)}>
            1
          </button>,
          <button key="ellipsis-prev" disabled>
            ...
          </button>
        );
      }
      if (currentPage < pageNumbers - 4) {
        visiblePages.push(
          <button key="ellipsis-next" disabled>
            ...
          </button>,
          <button key="last" onClick={() => paginate(pageNumbers)}>
            {pageNumbers}
          </button>
        );
      }
    }

    return (
      <div className="pagination">
        {currentPage > 1 && (
          <button onClick={() => paginate(currentPage - 1)}>Previous</button>
        )}
        {visiblePages}
        {currentPage < pageNumbers && (
          <button onClick={() => paginate(currentPage + 1)}>Next</button>
        )}
      </div>
    );
  };

  return (
    <div className="container">
      <button className="button" onClick={handleRefresh} disabled={loading}>
        {loading ? 'Refreshing...' : 'Refresh'}
      </button>
      <div className="graphWrapper">
        <div className="chart-container">
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="area"
            height={400}
          />
        </div>
        <div className="chart-container">
          <ReactApexChart
            options={pieChartOptions}
            series={pieChartData.map(data => data.y)}
            type="pie"
            height={400}
          />
        </div>
      </div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Page Name</th>
              <th>Formatted Date</th>
              <th>IP Address</th>
              <th>Count</th>
              <th>Visit Time</th>
              <th>Day of Week</th>
              <th>City</th>
              <th>Region</th>
              <th>Country</th>
              <th className='spacer'>Org</th>
              <th>Browser</th>
              <th>OS</th>
              <th>Loc</th>
              <th>Postal</th>
              <th>Timezone</th>
            </tr>
          </thead>
          <tbody>
            {currentVisits.map((visit, index) => (
              <tr key={index}>
                <td>{visit.page}</td>
                <td>{visit.formattedDate}</td>
                <td>{visit.visitTime}</td>
                <td>{visit.count}</td>
                <td>{visit.ipAddress}</td>
                <td>{visit.dayOfWeek}</td>
                <td>{visit.ipDetails.city}</td>
                <td>{visit.ipDetails.region}</td>
                <td>{visit.ipDetails.country}</td>
                <td>{visit.ipDetails.org}</td>
                <td>{visit.browser.name} {visit.browser.version}</td>
                <td>{visit.os.name} {visit.os.version}</td>
                <td>{visit.ipDetails.loc}</td>
                <td>{visit.ipDetails.postal}</td>
                <td>{visit.ipDetails.timezone}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {renderPagination()}
    </div>
  );
};

export default WebsiteVisitDetails;
