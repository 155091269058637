import axios from 'axios';
import React, { useEffect, useState } from 'react'
import "../CSS/Login.css"
import { Link, useParams } from 'react-router-dom';
import { changePasswordSchema } from '../FormValidationSchema';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import useTrackPageView from '../CustomHooks/useTrackPageView';

const initialValues = {
  Password: "",
  Confirm_Password: ""
}

const ResetPwdFromMail = ({ setProgress }: any) => {
  useTrackPageView('forgot password post mail action'); // Track page view for the About page

  const [loading, setloading] = useState(false);
  const paras = useParams();
  
  const [response, setResponse] = useState({
    status: null,
    message: null
  });

  const [SERVER_APP_URL, SET_SERVER_APP_URL] = useState<String>("");
  const [CLIENT_APP_URL, SET_CLIENT_APP_URL] = useState<String>("");
  const app_host_status = useSelector((state: any) => state.urlStateReducer);

  useEffect(() => {
    if (app_host_status) {
      SET_SERVER_APP_URL(app_host_status.SERVER_URL);
      SET_CLIENT_APP_URL(app_host_status.CLIENT_URL);
    }
  }, [SERVER_APP_URL, CLIENT_APP_URL])

  const api = `${SERVER_APP_URL}/api/user/reset-password/${paras.id}/${paras.token}`;
  const { values, errors, handleChange, handleSubmit, handleBlur, touched } = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: async (values, action) => {
      setloading(true);

      const token = localStorage.getItem("token");

      axios.post(api, { password: values.Password, password_confirmation: values.Confirm_Password }, {
        headers: {
          "Content-type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      })
        .then(res => {
          setResponse(res.data);
          if (res.data.status === "failed") {
            setloading(false);
            document.querySelector('.showLoginStats')?.classList.remove("successGreen");
          } else if (res.data.status === "success") {
            setloading(false);
            document.querySelector('.showLoginStats')?.classList.add("successGreen");
            action.resetForm();
          }
        })
        .catch(error => console.log(error));
    }
  });

  useEffect(() => {
    setProgress(40);
    setTimeout(() => {
      setProgress(100);
    }, 500);
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const setShowHide = () => {
    setShowPassword(!showPassword);
  }

  return (
    <div>
      <div className='LoginWrapper'>
        <div className="innerLoginBox">
          <h2>Reset Password</h2>
          <p className='heyEnter'>Enter new password</p>

          <div className="formFieldBox">
            <form onSubmit={handleSubmit}>
              <div className="inputRow">
                <span><i className="fa-solid fa-lock"></i></span>
                <input type="text" placeholder='Password' name='Password' onChange={handleChange} onBlur={handleBlur} value={values.Password} />
                {errors.Password && touched.Password ? <p className='formField-error'>{errors.Password}</p> : null}              </div>

              <div className="inputRow">
                <span><i className="fa-solid fa-lock"></i></span>
                <input type={showPassword ? "text" : "password"} name='Confirm_Password' placeholder='Confirm Password' id='password' onChange={handleChange} onBlur={handleBlur} value={values.Confirm_Password} />
                <label onClick={setShowHide}><i className={showPassword ? "fas fa-eye" : "fas fa-eye-slash"}></i></label>
                {errors.Confirm_Password && touched.Confirm_Password ? <p className='formField-error'>{errors.Confirm_Password}</p> : null}

              </div>
              {response.message ? <div className="showLoginStats" style={{ 'display': 'block' }}>{response.message}</div> : <div className="showLoginStats" style={{ 'display': 'none' }}>{response.message}</div>}
              <div>
                <input type='submit' className='LoginBtn' value={loading ? "Resetting password.." : "Reset Password"} />
                <Link to="/login" className='bckToLogin'>Back To Login</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPwdFromMail