import React from 'react'
import {createPortal} from 'react-dom'

const NotifPopup={
  position: 'relative' as 'relative',
  width: '60vw',
  maxHeight: '40vh',
  background: '#fff',
  boxShadow: '0 0 19px 8px rgba(0,0,0,0.1)',
  overflow:"auto"
}

const closeNotif={
  fontSize: '20px',
  width: '20px',
  height: '20px',
  left: '96%',
  top: '12px',
  position: 'sticky' as 'sticky',
  cursor: 'pointer',
  background: '#3EAF57',
  padding: '13px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
  borderRadius:'50%',
}

const Notification = ({closeNotifPop}:any) => {
  
  if(!closeNotifPop) return null;
  return createPortal(
      <>
      <div className='NotificationContainer' style={NotifPopup}>
        <div style={closeNotif} onClick={closeNotifPop} className='notificationBtnClose'><i className="fas fa-times"></i></div>
          <div className='notificationContent'>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Atque cupiditate optio ut iusto facere delectus. Debitis perferendis voluptate ex quidem repellat aliquid, ea, 
          </div>
      </div>
      </>
  , document.getElementById('portal') as Element);
}

export default Notification