import React from 'react'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

type slideSettings = {
    dots: boolean,
    infinite: boolean,
    speed: number,
    slidesToShow: number,
    slidesToScroll: number,
    initialSlide: number,
    responsive: object[]
}

const HappyStudentSlides = () => {
    var settings:slideSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    <div className='carousel'>

        <Slider {...settings}>
            <div className="box first"></div>
            <div className="box second"></div>
            <div className="box third"></div>
            <div className="box fourth"></div>
            <div className="box fifth"></div>
            <div className="box sixth"></div>
        </Slider>
    </div>
  )
}

export default HappyStudentSlides