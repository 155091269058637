import React from 'react'

import "../CSS/EntrollNowBtn.css"

const EntrollNowBtn = ({checkoutHandler,amount}:any) => {
  return (
    <div className="enrollButton">
        <button className="enrollNow" onClick={()=>checkoutHandler(amount)}><p>Enroll Now In ₹199/- <s><span style={{color:'yellow'}}>₹1499 </span></s></p><p>One time fee</p></button>
    </div>
  )
}

export default EntrollNowBtn