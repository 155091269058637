import React, {useEffect} from 'react'
import "../CSS/About.css"
import useTrackPageView from '../CustomHooks/useTrackPageView';


// type Props = {
//   color?: Number;
// };

const About = ({setProgress}:any) => {
  useTrackPageView('about'); // Track page view for the About page
  
  useEffect(() => {
    setProgress(40);
    setTimeout(() => {
      setProgress(100);
    }, 500);
  }, [setProgress]);
  


  return (
    <div className='aboutWrapper'>
      <p className='introFirst'>
        The website “www.wpskills.in” is the property of Rajnish Kumar.
      </p>

      <p className='introSecond'>Introduction</p>
      <br />
      <p className='introThird'>
        My name is Rajnish Kumar Niraj, and I am the founder of www.wpskills.in. I teach people about WordPress, Freelancing, and Digital Marketing. I have over 8 years of experience in the IT industry, and passionate about helping others learn new skills and grow their businesses.
      </p>
      <br />
      <p>
        I offer affordable, high-quality courses that are designed to help students succeed in today's competitive marketplace.
       <br /><br /> Whether you're looking to learn WordPress, Freelancing, or Digital Marketing, I can help you reach your goals. Contact me today to get started on your journey to success!
      </p>
    </div>
  )
}

export default About