import React, { useEffect, useState } from 'react';
import './CSS/App.css';
import LoadingBar from 'react-top-loading-bar'
import TopIntroSection from './Components/introVideo';
import HappyStudents from './Components/HappyStudents';
import PromoBnr1 from './Components/PromoBnr1';
import WhtLearn14Dys from './Components/WhtLearn14Dys';
import EntrollNowBtn from './Components/EntrollNowBtn';
import SyllabusGiftsSection from './Components/SyllabusGiftsSection';
import WorkAsCards from './Components/WorkAsCards';
import PromoBnr2 from './Components/PromoBnr2';
import Footer from './Components/Footer';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './Components/About';

import { Fragment } from 'react';
import Contact from './Components/Contact';
import Notification from './Components/Notification';
import Disclaimer from './Components/Disclaimer';
import Privacy from './Components/Privacy';
import Refund from './Components/Refund';
import Terms from './Components/Terms';
import Header from './Components/Header';
import Login from './Components/Login';
import MayShowNavbar from './Components/MayShowNavbar';
import AllVideos from './Components/AllVideos';
import { useDispatch, useSelector } from 'react-redux';
import ChangePassword from './Components/ChangePassword';
import ForgetPassword from './Components/ForgetPassword';
import { Pagenotfound } from './Components/Pagenotfound';
import ResetPwdFromMail from './Components/ResetPwdFromMail';
import axios from 'axios';
import PaymentSuccess from './Components/PaymentSuccess';
import EnrollnowAllPgs from './Components/EnrollnowAllPgs';
import Shipping from './Components/Shipping';
import { getProdURL, getDevURL } from "./Redux/appURLAction"
import WebsitVisitDetails from './Components/WebsitVisitDetails';

function App() {

  const [progress, setProgress] = useState(0);
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [showEnrollAll, setShowEnrollAll] = useState<boolean>();
  const [SERVER_APP_URL, SET_SERVER_APP_URL] = useState<String>("");
  const [CLIENT_APP_URL, SET_CLIENT_APP_URL] = useState<String>("");

  const dispatch = useDispatch();
  const app_host_status = useSelector((state: any) => state.urlStateReducer);
  useEffect(() => {

    const handleContextmenu = (e: React.MouseEvent<HTMLElement> | any) => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }


  }, []);

  useEffect(() => {
    if (process.env.APP_PUBLISH_MODE === 'production') {
      dispatch(getProdURL({
        SERVER_URL: "https://admin.wpskills.in",
        CLIENT_URL: "https://wpskills.in"
      }))
    } else {
      dispatch(getDevURL({
        SERVER_URL: "http://localhost:4500",
        CLIENT_URL: "http://localhost:8080"
      }))
    }

    if (app_host_status) {
      SET_SERVER_APP_URL(app_host_status.SERVER_URL);
      SET_CLIENT_APP_URL(app_host_status.CLIENT_URL);
    }
  }, [SERVER_APP_URL, CLIENT_APP_URL])


  const closeNotifPop = () => {
    setNotificationOpen(false);
    document.querySelector('.hdrTopFixed')?.classList.remove("removeColor");
    document.querySelector('.App')?.classList.remove("makeBlur");
    document.querySelector('body')?.classList.remove("removeScroll");
    document.querySelector('#portal')?.classList.remove("fullHeight");

    let setScroll = Number(localStorage.getItem("currScrollPos"));
    window.scrollTo({ top: setScroll, behavior: 'smooth' });
  }



  const checkoutHandler = async (amount: Number) => {
    const { data: { key } } = await axios.get(`${SERVER_APP_URL}/api/getkey`);

    const { data: { order } } = await axios.post(`${SERVER_APP_URL}/api/checkout`, {
      amount
    });

    var options = {
      key,
      amount: order.amount,
      currency: "INR",
      name: "WP Skills",
      description: "14 days Wordpress Learning Course",
      image: "https://wpskills.in/wp-author.jpg",
      order_id: order.id,
      callback_url: `${SERVER_APP_URL}/api/paymentverification`,
      theme: {
        "color": "#3EAF57"
      }
    };
    const razor = new (window as any).Razorpay(options);
    razor.on('payment.failed', function (response: any) {
      alert(response.error.description);
    });
    razor.open();
  }

  const userData = useSelector((state: any) => state.loggedUserData);


  useEffect(() => {
    <Header />
    if (userData.user || localStorage.getItem('user')) {
      setShowEnrollAll(false);
    } else {
      setShowEnrollAll(true);
    }
  }, [userData.user])

  return (
    <div className="App">
      {showEnrollAll && <EnrollnowAllPgs amount={235} checkoutHandler={checkoutHandler} />}
      <Router>
        <MayShowNavbar>
          <LoadingBar
            color='#3EAF57'
            progress={progress}
            onLoaderFinished={() => setProgress(0)}
          />
          {isNotificationOpen && <Notification closeNotifPop={closeNotifPop} />}
          <Header openNotif={setNotificationOpen} />
        </MayShowNavbar>
        <Routes>
          <Route path="/" element={
            <Fragment>
              <TopIntroSection amount={235} checkoutHandler={checkoutHandler} />
              <HappyStudents />
              <PromoBnr1 />
              <WhtLearn14Dys />
              <EntrollNowBtn amount={235} checkoutHandler={checkoutHandler} />
              <SyllabusGiftsSection amount={235} checkoutHandler={checkoutHandler} />
              <WorkAsCards amount={235} checkoutHandler={checkoutHandler} />
              <PromoBnr2 amount={235} checkoutHandler={checkoutHandler} />
            </Fragment>
          } />

          <Route path="/about" element={<About setProgress={setProgress} />} />
          <Route path="/contact" element={<Contact setProgress={setProgress} />} />
          <Route path="/disclaimer" element={<Disclaimer setProgress={setProgress} />} />
          <Route path="/privacy" element={<Privacy setProgress={setProgress} />} />
          <Route path="/refund" element={<Refund setProgress={setProgress} />} />
          <Route path="/terms" element={<Terms setProgress={setProgress} />} />
          <Route path="/login" element={<Login setProgress={setProgress} />} />
          <Route path="/shipping" element={<Shipping setProgress={setProgress} />} />
          <Route path="/course-videos" element={<AllVideos setProgress={setProgress} amount={235} checkoutHandler={checkoutHandler} />} />
          <Route path="/change-password" element={<ChangePassword setProgress={setProgress} />} />
          <Route path="/forget-password" element={<ForgetPassword setProgress={setProgress} />} />
          <Route path="/api/user/reset/:id/:token" element={<ResetPwdFromMail setProgress={setProgress} />} />
          <Route path="/paymentsuccess/:reference/:token" element={<PaymentSuccess />} />
          <Route path="/getvisit-details" element={<WebsitVisitDetails />} />
          <Route path="/*" element={<Pagenotfound setProgress={setProgress} />} />
        </Routes>
        <Footer />
      </Router>

    </div>
  );
}

export default App;
