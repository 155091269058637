import React, { useEffect, useState } from 'react'
import "../CSS/Login.css"
import "../CSS/ForgetPswrd.css"
import { Link } from 'react-router-dom'
import { useFormik } from 'formik';
import { forgetPasswordSchema } from '../FormValidationSchema';
import { useSelector } from 'react-redux';
import useTrackPageView from '../CustomHooks/useTrackPageView';

const initialValues = {
    Email: ""
}

const ForgetPassword = ({ setProgress }: any) => {
    useTrackPageView('forget-password'); // Track page view for the About page
    const [loading, setloading] = useState(false);
    const [loginStat, setloginStat] = useState<any>({});


    useEffect(() => {
        setProgress(40);
        setTimeout(() => {
            setProgress(100);
        }, 100);
    }, []);

    const [SERVER_APP_URL, SET_SERVER_APP_URL] = useState<String>("");
    const [CLIENT_APP_URL, SET_CLIENT_APP_URL] = useState<String>("");
    const app_host_status = useSelector((state: any) => state.urlStateReducer);

    useEffect(() => {
        if (app_host_status) {
            SET_SERVER_APP_URL(app_host_status.SERVER_URL);
            SET_CLIENT_APP_URL(app_host_status.CLIENT_URL);
        }
    }, [SERVER_APP_URL, CLIENT_APP_URL])


    const { values, errors, handleChange, handleSubmit, handleBlur, touched } = useFormik({
        initialValues,
        validationSchema: forgetPasswordSchema,
        onSubmit: async (values, action) => {
            setloading(true);
            let result = fetch(`${SERVER_APP_URL}/api/user/send-reset-password-email`, {
                method: 'POST',
                body: JSON.stringify({ email: values.Email }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            let response = await (await result).json();
            setloginStat(response);

            if (response.status === "failed") {
                setloading(false);
                document.querySelector('.showLoginStats')?.classList.remove("successGreen");
            }
            if (response.status === "success") {
                setloading(false);
                document.querySelector('.showLoginStats')?.classList.add("successGreen");
            }
            action.resetForm();
        }
    });



    // const handleRecoverPswd = async (event:any) => {
    //     event.preventDefault();
    //     if (email.length == 0) {
    //         setloginStat({
    //             'message': 'Email is required'
    //         })
    //     } else {
    //         setloading(true);
    //         let result = fetch("http://localhost:4500/api/user/send-reset-password-email", {
    //             method: 'POST',
    //             body: JSON.stringify({ email }),
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             }
    //         });

    //         let response = await (await result).json();
    //         console.log(response);
    //         setEmail("");
    //         setloginStat(response);

    //         if (response.status === "failed") {
    //             setloading(false);
    //             document.querySelector('.showLoginStats')?.classList.remove("successGreen");
    //         }
    //         if (response.status === "success") {
    //             setloading(false);
    //             document.querySelector('.showLoginStats')?.classList.add("successGreen");
    //             setEmail("");
    //         }

    //     }
    // }

    return (
        <div className='LoginWrapper'>
            <div className="innerLoginBox">
                <h2>Reset Your Password</h2>
                <p className='heyEnter'>Enter your email address and we will send you a link to reset your password</p>

                <div className="formFieldBox">
                    <form onSubmit={handleSubmit} >
                        <div className="inputRow">
                            <span><i className="fa-solid fa-envelope"></i></span>
                            <input type="text" placeholder='Enter your registered email' name='Email' id='username' onChange={handleChange} onBlur={handleBlur} value={values.Email} />
                            {errors.Email && touched.Email ? <p className='formField-error'>{errors.Email}</p> : null}
                        </div>

                        {loginStat.message ? <div className="showLoginStats" style={{ 'display': 'block' }}>{loginStat.message}</div> : <div className="showLoginStats" style={{ 'display': 'none' }}>{loginStat.message}</div>}

                        <div>
                            <input type='submit' className='LoginBtn' value={loading ? "Progress.." : "Recover Password"} />
                            <Link to="/login" className='bckToLogin'>Back To Login</Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword