import React,{useEffect} from 'react'
import "../CSS/Disclaimer.css"
import useTrackPageView from '../CustomHooks/useTrackPageView';

const Disclaimer = ({setProgress}:any) => {
  useTrackPageView('disclaimer'); // Track page view for the About page

  useEffect(() => {
    setProgress(40);
    setTimeout(() => {
      setProgress(100);
    }, 500);
  }, [setProgress]);


  return (
    <div className='disclaimerWrapper'>
        <p className="titleTxt">
          Disclaimer
        </p>

        <p className="commonPadding">
            This website and the course material within it are for informational purposes only. The information contained herein is not meant to be a substitute for professional WordPress training, nor is it intended to provide specific advice or recommendations for any individual website or blog.
        </p>
        
        <p className="commonPadding">
            By enrolling in this course, you agree that neither this website nor its owner will be held liable for any damages, direct or indirect, arising from your use of the information contained herein. This course is provided “as-is”, and no warranty, either express or implied, is given with respect to its accuracy or completeness.
        </p>
         <p className="commonPadding">
            Please note that some of the links on this website are affiliate links, and I may receive a commission if you purchase a product or service after clicking one of those links. I only recommend products and services that I believe will be helpful to you, and I always strive to provide honest and accurate information. Thank you for your support!
        </p>
        
    </div>
  )
}

export default Disclaimer